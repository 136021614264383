import React from "react";
import Footer from "../../common/Footer";
import styles from "./About.module.css";
import { useState } from "react";

const About = () => {
  const [displayStudentBenefits, setDisplayStudentBenefits] = useState(false);

  const toggleDisplayBenefits = (bool) => {
    setDisplayStudentBenefits(bool);
  }

  return (
    <>
    <div className={styles.aboutContainer}>
      <div className={styles.aboutContentContainer}>
      <div className={styles.aboutHeader}>
        <div className={styles.aboutUsText}>
          <h6>ABOUT US</h6>
          <h2>empower your startup with AI-driven recruitment</h2>
        </div>
        <img loading="lazy" alt="Resumes" src="/images/resumes.svg" />
      </div>

      <p>Our platform is designed to simplify the hiring process, connect startups with talented students, and accelerate your growth—all while saving time and resources.</p>

      {/* Let user choose to see benefits of students or recruiters */}
      <div className={styles.aboutChoice}>
        <div className={styles.aboutChoiceHeader}>
          <h2 onClick={() => toggleDisplayBenefits(true)}>For Students</h2>
          { (displayStudentBenefits === true) ? <hr></hr> : <></> }
        </div>

        <div className={styles.aboutChoiceHeader}>
          <h2 onClick={() => toggleDisplayBenefits(false)}>For Recruiters</h2>
          { (displayStudentBenefits === false) ? <hr></hr> : <></> }
        </div>
      </div>

      {/* Benefits */}
      <div className={styles.aboutBenefits}>
        {/* Cheaper */}
        <div className={styles.aboutBenefit}>
          <img className={styles.aboutIcon} loading="lazy" alt="Resumes" src="/images/bag.svg" />
          <h3>Cheaper</h3>
          { 
            (displayStudentBenefits === true)
            ? <p>Build your profile and find internships at a fraction of the cost</p>
            : <p>Get started for free and explore a pool of qualified, motivated candidates without added costs</p>
          }
        </div>

        {/* Faster */}
        <div className={styles.aboutBenefit}>
          <img className={styles.aboutIcon} loading="lazy" alt="Resumes" src="/images/bolt.svg" />
          <h3>Faster</h3>
          { 
            (displayStudentBenefits === true)
            ? <p>Connect with peers, find opportunities and get noticed in no time</p>
            : <p>Fill positions faster by recruiting qualified candidates in just a few clicks</p>
          }
        </div>

        {/* Easier */}
        <div className={styles.aboutBenefit}>
          <img className={styles.aboutIcon} loading="lazy" alt="Resumes" src="/images/gear.svg" />
          <h3>Easier</h3>
          { 
            (displayStudentBenefits === true)
            ? <p>Find internships, employment and create new connections with ease</p>
            : <p>Simplify your hiring process by using our AI chatbot, to find qualified students with ease</p>
          }
        </div>
      </div>
      {/* Footer */}
      <div className={styles.footerStyles}>
        <Footer />
      </div>
      
      </div>
    </div>  
    </>
  );
};

export default About;