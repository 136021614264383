import React, { useState, useEffect } from "react";
import DisplayProfile from "../p3/DisplayProfile";
import Footer from '../../../common/Footer';
import styles from "./StudentsP4.module.css";
import { auth, db, collection, getDocs, onAuthStateChanged, doc, updateDoc, arrayUnion, getDoc } from "../../../firebase-config";
import projectsData from "../../../datasets/projects.json";

const StudentsP4 = () => {
  const [students, setStudents] = useState([]);
  const [currentStudentIndex, setCurrentStudentIndex] = useState(0);
  const [commonGround, setCommonGround] = useState([]);
  const [userClubs, setUserClubs] = useState([]);
  const [userLocation, setUserLocation] = useState("");
  const [userAge, setUserAge] = useState("");
  const [matchingProjects, setMatchingProjects] = useState([]);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "Students", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          let syncedUsersArray = [];

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserClubs(userData.clubs || []);
            setUserLocation(userData.location || "");
            setUserAge(userData.age || "");

            if (userDocSnap.data().syncedUsers) {
              syncedUsersArray = userDocSnap.data().syncedUsers;
            }
          }

          const querySnapshot = await getDocs(collection(db, "Students"));
          const allStudents = [];

          querySnapshot.forEach((doc) => {
            const studentData = doc.data();
            if (
              doc.id !== user.uid &&
              !syncedUsersArray.includes(doc.id) &&
              studentData.fullName &&
              studentData.age &&
              studentData.gender &&
              studentData.headshot &&
              studentData.school &&
              studentData.major
            ) {
              allStudents.push({
                uid: doc.id,
                fullName: studentData.fullName,
                age: studentData.age,
                gender: studentData.gender,
                picture: studentData.headshot,
                school: studentData.school,
                location: studentData.location,
                clubs: studentData.clubs || [],
                major: studentData.major
              });
            }
          });

          setStudents(allStudents);
        } catch (error) {
          console.error("Error fetching student data: ", error);
        }
      }
    });

    return () => unsubscribe();
  }, [userAge]);

  useEffect(() => {
    if (students.length > 0) {
      const currentStudent = students[currentStudentIndex];
      const commonAttributes = [];

      const commonClubs = currentStudent.clubs.filter(club => userClubs.includes(club));
      if (commonClubs.length > 0) {
        commonAttributes.push(`Common Clubs and Societies: ${commonClubs.join(", ")}`);
      }

      if (currentStudent.location === userLocation) {
        commonAttributes.push("You Both Are Located In The Same City");
      }

      setCommonGround(commonAttributes);

      const majorProjects = projectsData.projects.find(
        (proj) => proj.major === currentStudent.major
      );

      if (majorProjects) {
        setMatchingProjects(majorProjects.projects);
        setCurrentProjectIndex(0);
      } else {
        setMatchingProjects([]);
      }
    }
  }, [currentStudentIndex, students, userClubs, userLocation, userAge]);

  const handleSkip = () => {
    if (students.length > 0) {
      setCurrentStudentIndex((prevIndex) => (prevIndex + 1) % students.length);
    }
  };

  const handleSync = async () => {
    const currentStudent = students[currentStudentIndex];
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "Students", user.uid);

        await updateDoc(userDocRef, {
          syncedUsers: arrayUnion(currentStudent.uid)
        });

        setStudents((prevStudents) =>
          prevStudents.filter((student) => student.uid !== currentStudent.uid)
        );
        setCurrentStudentIndex(0);
        alert(`You have synced with ${currentStudent.fullName}`);
      }
    } catch (error) {
      console.error("Error syncing user: ", error);
    }
  };

  const handleNextProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % matchingProjects.length);
  };

  if (students.length === 0) {
    return <div>Loading student profiles...</div>;
  }

  const currentStudent = students[currentStudentIndex];

  return (
    <div className={styles.studentsP4}>
      <div className={styles.studentsP4Child} />
      <section className={styles.studentsP4Inner}>
        <div className={styles.frameParent}>
          <div className={styles.startNetworkingWrapper}>
            <h1 className={styles.startNetworking}>start networking</h1>
          </div>
          <div className={styles.createYourProfileConnectWWrapper}>
            <h2 className={styles.createYourProfile}>
              {`Create your profile. Connect with peers. Work on projects. Get hired.`}
            </h2>
          </div>
        </div>
      </section>
      <section className={styles.frameSection}>
        <div className={styles.frameGroup}>
          <div className={styles.pictureParent}>
            <DisplayProfile 
              name={currentStudent.fullName} 
              age={currentStudent.age}  
              gender={currentStudent.gender}  
              profilePictureURL={currentStudent.picture} 
              school={currentStudent.school} 
              major={currentStudent.major}  
            />
            <div className={styles.doneParent}>
              <button className={styles.done} onClick={handleSkip}>
                <h2 className={styles.createProfile}>Skip</h2>
              </button>
              <button className={styles.done} onClick={handleSync}>
                <h2 className={styles.createProfile1}>Sync</h2>
              </button>
            </div>
            <div className={styles.doneParent}>
              <button className={styles.done1} onClick={() => window.location.href = '/students-dashboard'}>
                <h2 className={styles.createProfile}>View Dashboard</h2>
              </button>
            </div>
          </div>
          <div className={styles.pictureGroup}>
            <div className={styles.picture}>
              <h2 className={styles.commonGround}>Common Ground</h2>
              <div className={styles.comingSoonLabels}>
                {commonGround.length > 0 ? (
                  commonGround.map((attribute, index) => (
                    <div key={index}>
                      <i className={styles.featureComingSoon}>{attribute}</i>
                    </div>
                  ))
                ) : (
                  <i className={styles.featureComingSoon}>No common attributes</i>
                )}
              </div>
            </div>
            <div className={styles.picture1}>
              <h2 className={styles.commonGround}>Project Ideas</h2>
              <div className={styles.featureComingSoonWrapper}>
                {matchingProjects.length > 0 ? (
                  <div>
                    <h3 style={{ fontSize: "14px" }}>{matchingProjects[currentProjectIndex].title}</h3>
                    <p style={{ fontSize: "12px", lineHeight: "1.4" }}>{matchingProjects[currentProjectIndex].description}</p>
                    <button className={styles.nextProjectButton} onClick={handleNextProject}>
                      Next Project
                    </button>
                  </div>
                ) : (
                  <i className={styles.featureComingSoon}>No matching projects found</i>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default StudentsP4;
