import React, { useState, useEffect } from "react";
import Footer from "../../../common/Footer";
import { db, doc, getDoc } from "../../../firebase-config"; // Import Firestore methods
import { auth, onAuthStateChanged } from "../../../firebase-config"; // Import Firebase auth and onAuthStateChanged for user info
import styles from "./StartupsP3.module.css";
import ChatBot from "../../../common/ChatBot";
import ChatBotDemo from "../../../common/ChatBotDemo";

const StartupsP3 = () => {
	const [fullName, setFullName] = useState(""); // State to store the user's full name
	const [error, setError] = useState(null); // State to store potential errors
	const [hasAccess, setAccess] = useState(false);
	const [accessCode, setAccessCode] = useState("");
	const [codeActive, setCodeActive] = useState(false);
	const [demoActive, setDemoActive] = useState(false);
	const [careerSelected, setCareerSelected] = useState("");

	useEffect(() => {
		// Function to fetch user's fullName from Firestore
		const fetchUserInfo = async (user) => {
			try {
				const userDocRef = doc(db, "Startups", user.uid); // Reference to the user's document in Firestore
				const userDoc = await getDoc(userDocRef); // Fetch the document

				if (userDoc.exists()) {
					setFullName(userDoc.data().fullName); // Set fullName from Firestore data
					setCodeActive(userDoc.data().codeActive);
				} else {
					setFullName("User"); // Fallback if the document doesn't exist
				}
			} catch (error) {
				console.error("Error fetching user information:", error);
				setError("Failed to load user information."); // Set an error message
			}
		};

		// Listen for user state changes
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				fetchUserInfo(user); // Fetch user info if logged in
			} else {
				setFullName("Guest"); // Fallback for non-logged-in users
			}
		});

		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, []);

	const handleAccessCodeSubmit = async (e) => {
		e.preventDefault();
		try {
			const user = auth.currentUser;
			if (!user) {
				setError("Please log in to submit an access code.");
				return;
			}

			const userDocRef = doc(db, "Startups", user.uid); // Reference to the user's document
			const userDoc = await getDoc(userDocRef);

			if (userDoc.exists() && userDoc.data().accessCode === accessCode) {
				setAccess(true); // Set access to true if code is correct
			} else {
				setError("Invalid access code. Please try again.");
			}
		} catch (error) {
			console.error("Error validating access code:", error);
			setError("Failed to validate access code.");
		}
	};

	const handleDemoSubmit = () => {
		setDemoActive(!demoActive);
		setCareerSelected("");
		console.log("Demo mode: " + demoActive);
	};

	const handleCareerSelection = (career) => {
		setCareerSelected(career);
		console.log("Career Selected: " + career);
	};

	const renderHeader = () => {
    return (
      error ? <h1>Error</h1> : <><h1>Welcome, </h1><span>{fullName}</span></>
    );
	};

	const renderDemoNavigation = () => {
		return (
			<div className={styles.demoNavigation}>
				<button className={styles.careerButton} onClick={() => handleCareerSelection("Accounting")}>
					Accounting
				</button>
				<button className={styles.careerButton} onClick={() => handleCareerSelection("Business")}>
					Business
				</button>
				<button className={styles.careerButton} onClick={() => handleCareerSelection("Engineering")}>
					Engineering
				</button>
				<button className={styles.careerButton} onClick={() => handleCareerSelection("Nursing")}>
					Nursing
				</button>
			</div>
		);
	};

	const renderAccessForm = () => {
		return (
			<div className={styles.formContainer}>
				<form onSubmit={handleAccessCodeSubmit} className={styles.accessCodeForm} >
					<label className={styles.formLabel} htmlFor="accessCode">Access Code:</label>
					<input
						type="text"
						className={styles.accessCode}
						value={accessCode}
						placeholder="Access code..."
						onChange={(e) => setAccessCode(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								handleAccessCodeSubmit(e);
							}
						}}	
						required
					/>
				</form>
				<label className={styles.activateDemoText} >No access code? <span onClick={handleDemoSubmit}>Try a free demo</span> </label>
			</div>
		);
	};

	return (
	<>
		<div className={styles.p3Wrapper}>
			<div className={styles.p3PageContainer}>
			
			{hasAccess && codeActive && !demoActive ? (
				<div className={styles.chatBotContainer}>
					<ChatBot />
				</div>
			) : demoActive ? (
				<div className={styles.demoContainer}>
					<h2 className={styles.demoHeader}>Welcome, {fullName}</h2>
					<h3 className={styles.demoTextDescription}>Meet the students on our platform</h3>
					<div className={styles.demoNavigation}>
					{renderDemoNavigation()}
					</div>
					{/* Only display ChatBotDemo and Exit Demo button if a career is selected */}
					{careerSelected && careerSelected !== "" && (
						<>
							<ChatBotDemo careerPath={careerSelected} />
							<button className={styles.exitDemoButton} onClick={handleDemoSubmit}>Exit Demo</button>
						</>
					)}
				</div>
			) : (
				<div className={styles.p3ContentContainer}>
					<div className={styles.leftColumn}>
						<div className={styles.p3Header}>{renderHeader()}</div>
						<h2>Please enter an access code in order to meet the students on our platform.</h2>
						<h2>Alternatively, you may explore a demo featuring sample students free of charge.</h2>
					</div>
					<div className={styles.rightColumn}>
						<img loading="lazy" alt="Resumes" src="/images/hiring.svg" />
						{renderAccessForm()}
					</div>
				</div>
			)}
			</div>


			<div className={styles.footerStyles}>
				<Footer />
			</div>
			
		</div>
	</>
	);
};

export default StartupsP3;
