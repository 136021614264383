import React, { useState, useEffect } from "react";
import UserProfile from "./UserProfile";
import Footer from "../../../common/Footer";
import styles from "./StudentsP3.module.css";
import { db, collection, getDocs, doc, getDoc } from "../../../firebase-config"; 
import { auth, onAuthStateChanged } from "../../../firebase-config";
import { useNavigate } from "react-router-dom";

// Import projects.json
import projectsData from "../../../datasets/projects.json";

const StudentsP3 = () => {
  const [currentUserFullName, setCurrentUserFullName] = useState("");
  const [userMajor, setUserMajor] = useState("");
  const [matchingProjects, setMatchingProjects] = useState([]);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const docRef = doc(db, "Students", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setCurrentUserFullName(userData["fullName"]);
            setUserMajor(userData["major"]); // Fetch user's major
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userMajor) {
      const majorProjects = projectsData.projects.find(
        (proj) => proj.major === userMajor
      );

      if (majorProjects) {
        setMatchingProjects(majorProjects.projects);
        setCurrentProjectIndex(0);
      }
    }
  }, [userMajor]);

  const handleNextProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % matchingProjects.length);
  };

  const handleClick = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Students"));
      let otherUsersExist = false;

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.fullName !== currentUserFullName) {
          otherUsersExist = true;
        }
      });

      if (otherUsersExist) {
        navigate("/students-p4");
      } else {
        alert("Waiting for users to create their profile. Please check back in 24 hours");
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  return (
    <div className={styles.studentsP3}>
      <div className={styles.studentsP3Child} />
      <section className={styles.studentsP3Inner}>
        <div className={styles.frameParent}>
          <div className={styles.startNetworkingWrapper}>
            <h1 className={styles.startNetworking}>start networking</h1>
          </div>
          <div className={styles.createYourProfileConnectWWrapper}>
            <h2 className={styles.createYourProfile}>
              {`Create your profile. Connect with peers. Work on projects. Get hired.`}
            </h2>
          </div>
        </div>
      </section>
      <section className={styles.frameSection}>
        <div className={styles.frameGroup}>
          <div className={styles.pictureParent}>
            <UserProfile />
            <div className={styles.doneParent}>
              <button className={styles.done} onClick={handleClick}>
                <h2 className={styles.createProfile}>Get Started</h2>
              </button>
            </div>
          </div>
          <div className={styles.pictureGroup}>
            <div className={styles.picture}>
              <h2 className={styles.commonGround}>Common Ground</h2>
              <div className={styles.comingSoonLabels}>
                <i className={styles.featureComingSoon}>No common ground found</i>
              </div>
            </div>
            <div className={styles.picture1}>
              <h2 className={styles.commonGround}>Project Ideas</h2>
              <div className={styles.featureComingSoonWrapper}>
                {matchingProjects.length > 0 ? (
                  <div>
                    <h3 style={{ fontSize: "14px" }}>{matchingProjects[currentProjectIndex].title}</h3>
                    <p style={{ fontSize: "12px", lineHeight: "1.4" }}>
                      {matchingProjects[currentProjectIndex].description}
                    </p>
                    <button className={styles.nextProjectButton} onClick={handleNextProject}>
                      Next Project
                    </button>
                  </div>
                ) : (
                  <i className={styles.featureComingSoon}>No matching projects found</i>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default StudentsP3;
